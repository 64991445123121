import React from "react";
import Meta from "./../components/Meta";
import ReportSection from "./../components/ReportSection";
//import { requireAuth } from "./../util/auth";
import { useAuth } from "./../util/auth";
import { useTeam } from "../util/db";
import { useRouter } from "./../util/router";

function ReportPage(props) {
  //const auth = useAuth();vercel dev
  const router = useRouter();
  //const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);


   
  return (
    <>
      <Meta title="Invite Report" />

     

        <ReportSection
          bgColor="#ffffffff"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Your Team"
          subtitle=""
          shareId={router.query.share}
          ownerId={router.query.owner}
        />
      

     

    </>
  );
}

//export default requireAuth(ReportPage);
export default ReportPage;