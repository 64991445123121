import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import Footer from "./../components/Footer";

//["google", "facebook", "twitter"]

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        //providers={[]}
        afterAuthPath={router.query.next || "/dashboard"}
      />

<Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />

    </>
  );
}

export default AuthPage;
