import React, {useState, useEffect, useReducer, useRef, useCallback} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useRouter } from "./../util/router";
import queryString from 'query-string';
import { useAuth } from "./../util/auth";
import { useShareRequestOnce } from "../util/db";
import { ShareRequestLiveResults } from "./ShareRequestLiveResults";
import { ShareRequestVideoResults } from "./ShareRequestVideoResults";
import { Timestamp } from "firebase/firestore";
import capitalize from "@material-ui/core/utils/capitalize";
import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  forceH: {
    height: "800px",
  },
  forceScatterH: {
    minHeight: "800px",
  },
  bodyBackground: {
    backgroundImage: `url(./images/bk_high.jpg)`,
    backgroundSize: "cover",
    minHeight: "100vh",
    animation: "fadeInBodyAnimation ease 3s",
    animationIterationCount: "1",
    animationFillMode: "forwards",
  },
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  statbox: {
    padding: '10px',
  },
  white: {
    color: '#ffffff',
  },

  white60: {
    color: 'rgba(255, 255, 255, 0.6)',
    
  },
  scoreicon: {
    //paddingTop: '5px',
    height: '100%',
    width: '100%',
  },
  imgheader: {
    // Add bottom margin if element below
    height: '400px'
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  subtitle: {
    maxWidth: 700,
    display: "inline-block",
  },
  gridItem: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  buffer: {
    marginBottom: "30px",
  },
  doBlur: {
    filter: "blur('25px')",
  },
  eventImage: {
    maxWidth: "100%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "10px",
  },
  headline: {
    //fontWeight: "800",
    marginBottom: '0px',
    color: "#ffffff",
  },
  of100: {
    fontSize: ".8em",
    color: 'rgba(255, 255, 255, 0.6)',
  },
  peopleCard: {
    background: '#062A53',
    borderRadius: '10px',
  }
}));



function ReportSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const scrollRef = useRef();
  
  const overviewRef = useRef();
  const detailRef = useRef();
  const timelineRef = useRef();

  const [team, setTeam] = useState({});

  //const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);

  const { shareId, ownerId, ...otherProps } = props;

  const { data: shareRequest, status: resultsStatus, error: resultsError, } = useShareRequestOnce(shareId);
  const [isPaid, setIsPaid] = useState(false);

  const [isPaidOVERRIDE, setIsPaidOVERRIDE] = useState(false);

  const [currentView, setCurrentView] = useState("scatter");
  const [timeLabel, setTimeLabel] = useState("Last hour");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [disableDownload, setDisableDownload] = useState(true);

  const [queryStartRange, setQueryStartRange] = useState("-1h");
  const [queryAggWindow, setQueryAggWindow] = useState("1h");
  const [legend, setLegend] = useState("Last 60 minutes");
  const [downloadData, setDownloadData] = useState([]);
  const [downloadRange, setDownloadRange] = useState([]);

  const [stripeSession, setStripeSession] = useState();

  const [immersion, setImmersion] = useState(0);
  const [safety, setSafety] = useState(0);
  const [shape, setShape] = useState("flat");

  useEffect(() => {
    //console.log("SHARE: " + shareId);
    //console.log("OWNER: " + ownerId);
    
    const obj = queryString.parseUrl(window.location.href);
    //console.log("obj: " + obj);
    const pOver = obj.query['p'];
    //console.log("pOver: " + pOver);

    if (!!pOver) {
      //console.log("!!pOver: " + pOver);
      //console.log("REACT_APP_REPORT_KEY: " + process.env.REACT_APP_REPORT_KEY);
      if (pOver === process.env.REACT_APP_REPORT_KEY) {
        setIsPaidOVERRIDE(true);
      }
    }

    
  },[]);
 

  const handleDownloadImage = async (element, name) => {
    //const element: any = overviewRef.current;
    const canvas = await html2canvas(element);
  
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
  
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = name + '_image.jpg';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const downloadOverviewSection = useCallback(() => {
    handleDownloadImage(overviewRef.current, 'SIX_overview');
  }, [shareRequest]);

  


  //console.log("team: " + team);
  //console.log("shareRequest: " + JSON.stringify(shareRequest));
//[Log] shareRequest OwneR: e8zXYK7lZgsr7hWWFmOE (main.d9ebd7101b427df99a84.hot-update.js, line 153)


  useEffect(() => {

    if (!!shareRequest) {
        //console.log("shareRequest OwneR: " + shareRequest.owner);
        
        if (shareRequest.owner === ownerId) {
           // console.log("shareRequest: IS VALID");
            if (shareRequest.paid && shareRequest.paid == true) {
                setIsPaid(true);
            } else {
              if (isPaidOVERRIDE == true) {
                setIsPaid(true);
              } else {
                setIsPaid(false);
              }
            }


        } else {
            console.log("shareRequest: IS NOT VALID");
        }
    }
    
    
  },[shareRequest]);



  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadDataset = (data) => {
    //console.log("handleDownloadDataset");
    setDownloadData(data);
  }


  const updateScores = (tmpImmersion, tmpSafety, tmpShape) => {
    //console.log("handleDownloadDataset");
    setImmersion(tmpImmersion);
    setSafety(tmpSafety);
    setShape(tmpShape);
  }


  const getFormattedDateString = (dateobject) => {
    if (dateobject && dateobject.seconds != undefined) {
    const timestamp = new Timestamp(dateobject.seconds, dateobject.nanoseconds);

    return moment(new Date(timestamp.toDate())).format("MMM D, h:mma")
    } else {
        return moment(dateobject).format("MMM D, h:mma")
    }
}
  


  const handleClose = () => {
    setAnchorEl(null);
  };
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const options = ['Last hour', 'Last day', 'Last week', 'Last month'];

  //console.log(team.team_id);
//https://immersion-prod-saas-docs.s3.amazonaws.com/placeholders/large-69.svg

  useEffect(() => {
    //console.log("changed state");
    forceUpdate();
  },[queryStartRange, queryAggWindow]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.bodyBackground}
    >

 


      <Container ref={overviewRef}>
        {/** Start Page Header */}
        <Grid container={true} className={classes.buffer}>

        <Grid item={true} xs={12} md={12} className={classes.gridItem} >
        {shareRequest && 
        <Box component="header" className={classes.imgheader} style={{ height: '400px', backgroundImage: `url(${shareRequest.image_url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        }}>

        <div style={{height: '100%', background: 'rgba(145, 1, 178, 0.2)', padding: '10px'}}>
        <Grid container>
        <Grid item={true} xs={12}  alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" className={classes.actionBar}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  

                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon onClick={downloadOverviewSection}/> 
                  </Button>
                  

                  <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`tuesday_event_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>


                  </ButtonGroup>
                </Grid>
                </Grid>



        </div>

        </Box>
}

        </Grid>

          <Grid item={true} xs={12} md={6} className={classes.gridItem} >
            <Box className={classes.root} >

            
            {shareRequest && 
            <img src={`/images/shapes/1_${shape}.svg`} style={{width: "100%", height: "100%"}} className={classes.xeventImage}/>
            }

            </Box>
          </Grid>

{/** HOLD AREA
 
  <Box className={classes.statbox} style={{height: '100%', background: 'rgba(145, 1, 178, 1.0)'}}></Box>
<Grid container={true} direction="column" justifyContent="flex-end" alignItems="stretch" style={{height: '100%'}}>
              <Grid item={true} >
                ertert ert ert 
              </Grid>
              </Grid>
 */}


          <Grid item={true} xs={12} md={6} className={classes.gridItem} alignItems="stretch" style={{padding: '10px', background: 'rgba(145, 1, 178, 1.0)'}}>
            
         


              <Grid container={true} direction="column" justifyContent="flex-start" alignItems="stretch" style={{height: '100%'}}>
              <Grid item={true} >

              {shareRequest && <>

{shareRequest && shareRequest.type === "recorded" && (
  <><Typography variant="h6" className={classes.white60}>Recorded</Typography></>
)}

{shareRequest && shareRequest.type === "live" && (
  <><Typography variant="h6" className={classes.white60}>Live Event</Typography></>
)}


<Typography variant="h3" className={classes.headline} paragraph={true}>
  {shareRequest.title}
</Typography>


<Typography variant={`p`}  className={classes.headline} gutterBottom={false} paragraph={true}>
{getFormattedDateString(shareRequest.start)} → {getFormattedDateString(shareRequest.end)}
</Typography>

<Typography variant={`p`}  className={classes.headline} gutterBottom={false} paragraph={true}>
{shareRequest && shareRequest.from_name !== "Default" ? shareRequest.from_name : 'A Friend'}
</Typography>
</>
            }

              </Grid>

              <Grid item={true} >
{/**style={{minHeight: '210px'}} */}
              <Grid container={true} >

              <Grid item={true} xs={1} >
              <img src="/images/icons/brain.svg" className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {immersion}<Typography variant="p"  className={classes.of100} gutterBottom={false}>/100</Typography>
              </Typography>
              </Grid>

              <Grid item={true} xs={1} >
              <img src="/images/icons/safety.svg" className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {safety}<Typography variant="p"  className={classes.of100} gutterBottom={false}>/100</Typography>
              </Typography>
              </Grid>


              <Grid item={true} xs={1} >
              <img src="/images/icons/shape-valley.svg" className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {capitalize(shape)}
              </Typography>
              </Grid>

              </Grid>



              
              </Grid>

              </Grid>


            
          </Grid>
          
        </Grid>
        {/** End Page Header */}

        {/** start people stats */}
        <Grid container={true} spacing={2}>
        <Grid item={true} xs style={{background: 'url("/images/dot.png")'}}>
        </Grid>

        <Grid item={true} xs={3} md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant="p" className={classes.headline} gutterBottom={false}>ACCEPTED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{shareRequest && shareRequest.accept_count >= 0 ? shareRequest.accept_count : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        <Grid item={true} xs={3}  md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant="p" className={classes.headline} gutterBottom={false}>COMPLETED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{shareRequest && shareRequest.completed_count >= 0 ? shareRequest.completed_count : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        <Grid item={true} xs={3}  md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant="p" className={classes.headline} gutterBottom={false}>REJECTED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{shareRequest && shareRequest.deny_count >= 0 ? shareRequest.deny_count : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        </Grid>
        {/** End people stats */}

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}





                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "live" && 
                    
                    <ShareRequestLiveResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} scoreCallback={updateScores} />
                    
                }

                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "recorded" && (
                    <>
                    

                    <ShareRequestVideoResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} scoreCallback={updateScores}/>
                    </>
                )}


                {shareRequest && shareRequest.owner != ownerId && (
                    <Typography variant="h5" paragraph={true}>
                    <strong>Not a valid report request...</strong><br></br>
                  </Typography>
                )}





       {/**   
  
          <Box>
          <Card>
            <CardContent className={classes.cardContent}>
              <Box className={classes.forceScatterH}>

              {shareRequest && shareRequest.owner == ownerId && (
                <>

                <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6} >
                <Typography variant="h6" color="secondary">
                {shareRequest && shareRequest.type === "recorded" && (
                  <><LiveTvIcon color="secondary" fontSize="medium" /> Recorded</>
                )}

                {shareRequest && shareRequest.type === "live" && (
                  <><EventIcon color="secondary" fontSize="medium" /> Live Event</>
                )}

                </Typography>
                </Grid>

                <Grid item={true} xs={12} md={6} alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" className={classes.actionBar}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  

                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon /> 
                  </Button>
                  

                  <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`tuesday_event_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>


                  </ButtonGroup>
                </Grid>


                  
                
                <Grid item={true} xs={12} md={4} >
                <img src={shareRequest.image_url} style={{maxWidth: "100%"}} className={classes.eventImage}/>
                </Grid>
                

                <Grid item={true} xs={12} md={8} >

                <Typography variant="h3" paragraph={true}>
                  <strong>{shareRequest.title}</strong>
                </Typography>

                <Typography variant="p" paragraph={true}>
                {shareRequest.desc}
                </Typography>

                <Typography variant="p" paragraph={true}>
                {getFormattedDateString(shareRequest.start)} → {getFormattedDateString(shareRequest.end)}
                </Typography>



                </Grid>

                
                </Grid>
                

                
                </>
                )}


                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "live" && (
                    <>
                    

                    <ShareRequestResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} />
                    </>
                )}

                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "recorded" && (
                    <>
                    

                    <ShareRequestVideoResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} />
                    </>
                )}


                {shareRequest && shareRequest.owner != ownerId && (
                    <Typography variant="h5" paragraph={true}>
                    <strong>Not a valid report request...</strong><br></br>
                  </Typography>
                )}

              </Box>
            </CardContent>
          </Card>
          </Box>
          
*/}
        


        
      </Container>
    </Section>
  );
}

export default ReportSection;
