import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Section from "./Section";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  square: {
    border: "0px solid black",
    position: "relative",
    width: 120,
    "&::before": {
      display: "block",
      content: "''",
      paddingBottom: "100%"
    }
  }

}));



function StatsSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: `Academic Citations`,
      stat: "20k",
    },
    {
      title: "Years of Research",
      stat: "20",
    },
    {
      title: "Third Party Proof",
      stat: "12k",
    },
    {
      title: "Brains Measured",
      stat: "+1m",
    },
   
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

{/**
 * 
 * <Box textAlign="center" alignItems={"center"} justifyItems={"center"}></Box>
 */}


      <Container>
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={3} key={index} style={{}} className={classes.square}>
              
              <Box
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(to bottom, #21EAFF 5%, #32FFBA 100%)",
                borderRadius: "50%",
                flexDirection: "column",
                color: "#000",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            >
              <div><Typography variant="h6" color="textPrimary" align="center" paragraph={true}>{item.title}</Typography><br></br></div>
              <div><Typography variant="h4" align="center">{item.stat}</Typography></div>
            </Box>



                
              
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default StatsSection;


