import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import { Link } from "./../util/router";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  contentBox: {
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      justifyContent: "center",
  },
  contentButton: {
    maxWidth: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30px",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "800px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
      
    },
  },
}));

function ContentSectionImageRight(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Lorem Ipsum",
      body: "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
      image: "https://uploads.divjoy.com/undraw-fish_bowl_uu88.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Nunc nulla mauris, laoreet vel cursus lacinia, consectetur sit amet tellus.",
      image: "https://uploads.divjoy.com/undraw-directions_x53j.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lobortis, metus et mattis ullamcorper",
      image: "https://uploads.divjoy.com/undraw-stability_ball_b4ia.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo",
      image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        
        
          <Grid container={true}>
            
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                
              >
                <Box p={6} className={classes.contentBox}>
                  
                  <Typography variant="h3" gutterBottom={true} color="textPrimary">
                  {props.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textPrimary">{props.bodyText}</Typography>

                  <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            className={classes.contentButton}
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>

                </Box>
              </Grid>
            
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    
                      <img src={props.mainImage} style={{borderRadius: "25px"}} alt='' />
                     
                  </div>
                 
                </Box>
              </Grid>
            
          </Grid>
        
      </Container>
    </Section>
  );
}

export default ContentSectionImageRight;
