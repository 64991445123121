import React, {useState, useEffect, useReducer, useRef, useCallback} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useTeam } from "../util/db";
import Button from '@material-ui/core/Button';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TimelineIcon from '@material-ui/icons/Timeline';
import PieChartIcon from '@material-ui/icons/PieChart';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { TimelineChart } from './TimelineChart';
import { ScatterChart } from './ScatterChart';
import { PieChart } from './PieChart';
import CsvDownloadButton from 'react-json-to-csv'
import CustomCvsButton from "./CustomCvsButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import html2canvas from 'html2canvas';
import ImageIcon from '@material-ui/icons/Image';


const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
    background: '#fff',
  },
  forceH: {
    height: "800px",
  },
  forceScatterH: {
    height: "800px",
  },
  bodyBackground: {
    backgroundImage: `url(./images/six-enterprise-master-bkgrd.jpg)`,
    backgroundSize: "cover",
    minHeight: "100vh",
    animation: "fadeInBodyAnimation ease 3s",
    animationIterationCount: "1",
    animationFillMode: "forwards",
  },
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    maxWidth: 700,
    display: "inline-block",
  },
  gridItem: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  buffer: {
    marginBottom: "30px",
  },
  headline: {
    //fontWeight: "800",
    //color: "#ffffff",
  },
  button: {
    background: '#000000',
  },
}));



function DashboardSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const chartRef = useRef();

  const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);

  const [currentView, setCurrentView] = useState("scatter");
  const [timeLabel, setTimeLabel] = useState("Last hour");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [disableDownload, setDisableDownload] = useState(true);

  const [queryStartRange, setQueryStartRange] = useState("-1h");
  const [queryAggWindow, setQueryAggWindow] = useState("1h");
  const [legend, setLegend] = useState("Last 60 minutes");
  const [downloadData, setDownloadData] = useState([]);
  const [downloadRange, setDownloadRange] = useState([]);

  const [openCustomDates, setOpenCustomDates] = React.useState(false);
  const [customAggWindow, setCustomAggWindow] = React.useState('1m');

  const [useCustomDates, setUseCustomDates] = React.useState(false);
  const [customStart, setCustomStart] = React.useState(new Date());
  const [customEnd, setCustomEnd] = React.useState(new Date());

  

  const handleCustomStartChange = (event) => {
    console.log(event.target.value);
    setCustomStart(event.target.value);
  };

  const handleCustomEndChange = (event) => {
    console.log(event.target.value);
    setCustomEnd(event.target.value);
  };

  const handleChange = (event) => {
    setCustomAggWindow(event.target.value);
  };

  const handleOpenCustomDates = () => {
    setOpenCustomDates(true);
  };

  const handleCloseCustomDates = () => {
    setOpenCustomDates(false);
  };


  const handleUpdateCustomDates = () => {

    console.log("Custom Start Date", customStart);
    console.log("Custom End Date", customEnd);
    console.log("Custom Agg Window", customAggWindow);

    
    setOpenCustomDates(false);
  };



  const handleDownloadDataset = (data, range) => {
    console.log("handleDownloadDataset");
    setDownloadData(data);
    setDownloadRange(range);
    setDisableDownload(false);
  }

  const changeView = (view) => {
    setCurrentView(view)
    setDisableDownload(true);

    if (selectedIndex === 0) {
      //last hour
      setLegend("Last 60 minutes")
      setQueryStartRange("-1h");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "1h" : "1m");
    } else if (selectedIndex === 1) {
      //last day
      setLegend("Last 12 hours")
      setQueryStartRange("-12h");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "12h" : "1h");
    } else if (selectedIndex === 2) {
      //last week
      setLegend("Last 7 days")
      setQueryStartRange("-7d");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "7d" : "1d");
    } else if (selectedIndex === 3) {
      //last month
      setLegend("Last 30 days")
      setQueryStartRange("-30d");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "30d" : "1d");
    }


  };

  




  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    //console.log(index + " == " + options[index]);
    setSelectedIndex(index);
    setTimeLabel(options[index])
    setDisableDownload(true);

    if (index === 0) {
      //last hour
      setLegend("Last 60 minutes")
      setQueryStartRange("-1h");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "1h" : "1m");
    } else if (index === 1) {
      //last day
      setLegend("Last 12 hours")
      setQueryStartRange("-12h");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "12h" : "1h");
    } else if (index === 2) {
      //last week
      setLegend("Last 7 days")
      setQueryStartRange("-7d");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "7d" : "1d");
    } else if (index === 3) {
      //last month
      setLegend("Last 30 days")
      setQueryStartRange("-30d");
      setQueryAggWindow(currentView === "scatter" || currentView === "pie" ? "30d" : "1d");
    } else if (index === 4) {
      setOpenCustomDates(true);
    }



    //setDisableDownload(false)
    setAnchorEl(null);
  };


  const getAggWindowScatterPieChart = () => {
    //console.log(`getAggWindowPieChart = ${selectedIndex}`);

    if (selectedIndex === 0) {
      return "1h"
    } else if (selectedIndex === 1) {
      return "12h"
    } else if (selectedIndex === 2) {
      return "7d"
    } else if (selectedIndex === 3) {
      return "30d"
    }

  };

  const getStartRangeScatterPieChart = () => {
    //console.log(`getStartRangePieChart = ${selectedIndex}`);

    if (selectedIndex === 0) {
      return "-1h"
    } else if (selectedIndex === 1) {
      return "-12h"
    } else if (selectedIndex === 2) {
      return "-7d"
    } else if (selectedIndex === 3) {
      return "-30d"
    }

  };

  const getAggWindowLineChart = () => {
    //console.log(`getAggWindowLineChart = ${selectedIndex}`);

    if (selectedIndex === 0) {
      return "1m"
    } else if (selectedIndex === 1) {
      return "1h"
    } else if (selectedIndex === 2) {
      return "1d"
    } else if (selectedIndex === 3) {
      return "1d"
    }

  };

  const getStartRangeLineChart = () => {
    //console.log(`getStartRangeLineChart = ${selectedIndex}`);

    if (selectedIndex === 0) {
      return "-1h"
    } else if (selectedIndex === 1) {
      return "-12h"
    } else if (selectedIndex === 2) {
      return "-7d"
    } else if (selectedIndex === 3) {
      return "-30d"
    }

  };

  const handleDownloadImage = async (element, name) => {
    //const element: any = overviewRef.current;
    const canvas = await html2canvas(element);
  
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
  
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = name + '_image.jpg';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };



  const downloadDetailTimelineSection = useCallback((title) => {
    handleDownloadImage(chartRef.current, 'SIX_detail_timeline');
  }, []);

  const downloadDetailScatterSection = useCallback((title) => {
    handleDownloadImage(chartRef.current, 'SIX_detail_scatter');
  }, []);

  const downloadDetailPieSection = useCallback((title) => {
    handleDownloadImage(chartRef.current, 'SIX_detail_pie');
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const options = ['Last hour', 'Last day', 'Last week', 'Last month'];
  const optionsTimeline = ['Last hour', 'Last day', 'Last week', 'Last month', 'Custom'];

  //console.log(team.team_id);

  useEffect(() => {
    //console.log("changed state");
    forceUpdate();
  },[queryStartRange, queryAggWindow]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.bodyBackground}
    >
      <Container  ref={chartRef}>
        {/** Start Page Header */}
        <Grid container={true} className={classes.buffer}>
          <Grid item={true} xs={12} md={6} className={classes.gridItem} >
            <Box component="header" className={classes.root} >

              <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                {team && team.name !== "Default" ? team.name : 'Your Team'}
              </Typography>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={6} className={classes.actionBar} >
              <div>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('scatter')} className={classes.button} disabled={currentView === 'scatter'}>
                      <ScatterPlotIcon />
                  </Button>

                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('time')} className={classes.button} disabled={currentView === 'time'}>
                      <TimelineIcon />
                  </Button>

                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('pie')} className={classes.button} disabled={currentView === 'pie'}>
                      <PieChartIcon />
                  </Button>
                  
                  <Button variant="contained" color="secondary" size="medium" onClick={handleClick} className={classes.button} endIcon={<ArrowDropDownIcon />} >
                      {timeLabel}
                  </Button>

                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>

                    { currentView && currentView !== 'time' && 

                      options.map((option, index) => (
                      <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                          {option}
                      </MenuItem>
                      ))

                      }

                      { currentView && currentView === 'time' && 

                      optionsTimeline.map((option, index) => (
                      <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                          {option}
                      </MenuItem>
                      ))

                      }


                  </Menu>

                  <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`tuesday_export_${currentView}_${downloadRange}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={disableDownload} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>

                  {/*
                  <Button variant="contained" color="secondary" disabled={disableDownload} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </Button>
                      */}


              </ButtonGroup>
              </div>
          </Grid>
        </Grid>
        {/** End Page Header */}


        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}



          {team && currentView && currentView === 'scatter' && (

          <Box style={{background: '#fff'}}>
          <Card style={{background: '#fff'}}>
            <CardContent className={classes.cardContent}>
            <CardHeader
              action={
                <Button variant="contained" color="secondary" size="medium" className={classes.button} >
                  <ImageIcon onClick={downloadDetailScatterSection}/> 
                </Button>
              }
              title={`Team (${timeLabel})`}
            />


              <Box className={classes.forceScatterH}>
                <ScatterChart downloadCallback={handleDownloadDataset} startRange={getStartRangeScatterPieChart()} aggWindow={getAggWindowScatterPieChart()} legend={legend} groupId={team.teamid} />
              </Box>
            </CardContent>
          </Card>
          </Box>
          )}

          {team && currentView && currentView === 'time' && (

          <Box>
          <Card>
            <CardContent className={classes.cardContent}>
            <CardHeader
              action={
                <Button variant="contained" color="secondary" size="medium" className={classes.button} >
                  <ImageIcon onClick={downloadDetailTimelineSection}/> 
                </Button>
              }
              title={`Timeline (${timeLabel})`}
            />
              <Box className={classes.forceH}>
                
                <TimelineChart downloadCallback={handleDownloadDataset} startRange={getStartRangeLineChart()} aggWindow={getAggWindowLineChart()} legend={legend} groupId={team.teamid} />
              </Box>
            </CardContent>
          </Card>
          </Box>

          )}

          {team && currentView && currentView === 'pie' && (

          <Box>
            <Card>
              <CardContent className={classes.cardContent}>
              <CardHeader
              action={
                <Button variant="contained" color="secondary" size="medium" className={classes.button} >
                  <ImageIcon onClick={downloadDetailPieSection}/> 
                </Button>
              }
              title={`Breakout (${timeLabel})`}
            />
                <Box className={classes.forceH}>
                  
                  <PieChart downloadCallback={handleDownloadDataset} startRange={getStartRangeScatterPieChart()} aggWindow={getAggWindowScatterPieChart()} legend={legend} groupId={team.teamid} />

                </Box>
              </CardContent>
            </Card>
          </Box>

          )}



      <Dialog
        fullWidth={true}
        maxWidth="sm"
        classes={classes}
        open={openCustomDates}
        onClose={handleCloseCustomDates}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" type={"live"} onClose={handleCloseCustomDates}>
             Set Custom Date Range
        </DialogTitle>

        <DialogContent>
          {/*<DialogContentText>
            {activeShareRequest !== null ? activeShareRequest.desc : "desc"}
        </DialogContentText>*/}
          
          <Grid container={true} spacing={4}>
            
            <Grid item={true} xs={12} md={6}>
            <TextField
              id="start-datetime-local"
              label="Start"
              type="datetime-local"
              defaultValue={new Date().toISOString}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleCustomStartChange}
            />
            </Grid>

            <Grid item={true} xs={12} md={6}>
            <TextField
              id="end-datetime-local"
              label="End"
              type="datetime-local"
              defaultValue={new Date().toISOString}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleCustomEndChange}
            />
            </Grid>



            <Grid item={true} xs={12} md={12}>    
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.textField}>Group by:</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={customAggWindow} onChange={handleChange}>
                <FormControlLabel value="1s" control={<Radio />} label="Second" />
                <FormControlLabel value="1m" control={<Radio />} label="Minute" />
                <FormControlLabel value="1h" control={<Radio />} label="Hour" />
                
              </RadioGroup>
            </FormControl>
            </Grid>

          </Grid>
            
         


          

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleCloseCustomDates} size="medium">
            Close
          </Button>
          <Button variant="contained" color="secondary" onClick={handleUpdateCustomDates} size="medium">
            Update
          </Button>
        </DialogActions>
      </Dialog>




        
      </Container>
    </Section>
  );
}

export default DashboardSection;
