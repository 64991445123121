import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import HeroSection2 from "./../components/HeroSection2";
import HomeSectionHero from "./../components/HeroSectionHome";

function FaqPage(props) {
  return (
    <>
      <Meta title="Faq" />
      <HomeSectionHero
        bgColor="primary"
        size="large"
        bgImage="/images/six-enterprise-header-faq.jpg"
        bgImageOpacity={1.0}
        title="This Stuff Gets Complex"
        buttonText="Get Answers"
        buttonColor="#ff0000"
        buttonPath="#faq"
        subtitle="We dropped a few answers to the most common questions we here below."
      />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage="/images/six_pattern_bkgrd.jpg"
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

<Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />

    </>
  );
}

export default FaqPage;
