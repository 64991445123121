import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import HomeSectionHero from "./../components/HeroSectionHome";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";


function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HomeSectionHero
        bgColor="primary"
        size="large"
        bgImage="/images/six-enterprise-header-about.jpg"
        bgImageOpacity={1.0}
        title="Real Science. Real Results."
        buttonText="Get Started"
        buttonColor="#ff0000"
        buttonPath="/pricing"
        subtitle="This team spent 20 years figuring out how to measure what people love. And our customers have used it to build happier and more productive teams; ensure patient compliance and wellness; and get objective KPI’s to create effective, lasting improvements for emotional fitness."
      />
      <StatsSection
        bgColor="darkblue"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />


<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="About SIX"
        bodyText={`SIX - an app powered by Immersion Neuroscience measures what people love using the smartwatch they already wear. 

        The app delivers daily scores & insights to users about what brings them connection and joy for happier, healthier lives. 
        
        When users share this information, leaders and social connections can identify high and low moments so they can improve happiness or intervene when needed. 
        
        SIX identifies your moments of peak connection so you can measure what you love and focus on creating more of it.
        
        SIX can also predict downturns in emotional fitness up to two days in advance so you can determine when you need to slow down or step back.
         `}
        subtitle="All the features you need to move faster"
        mainImage="/images/six_phone_app_homepage.png"
        buttonText="Learn More"
        buttonColor="secondary"
        buttonPath="/contact"
      />



      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage="/images/six_pattern_bkgrd.jpg"
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

<Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />


    </>
  );
}

export default AboutPage;
