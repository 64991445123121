import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Section from "./Section";
import ReauthModal from "./ReauthModal";
import SettingsNav from "./SettingsNav";
import SettingsGeneral from "./SettingsGeneral";
import SettingsTeam from "./SettingsTeam";
import SettingsInterventions from "./SettingsInterventions";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import { useAuth } from "./../util/auth";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardMedia, CardContent, CardActions, CardActionArea } from "@material-ui/core";
import { Link } from "./../util/router";
import Typography from "@material-ui/core/Typography";
import { useTeam } from "../util/db";


function SettingsSection(props) {
  const auth = useAuth();
  const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    interventions: true,
    billing: true,
  };

  const section = validSections[props.section] ? props.section : "general";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };


  useEffect(() => {
    
    document.body.style.backgroundColor = "";
    document.body.style.backgroundImage = "";
    document.body.style.backgroundSize = "cover";
    
  });


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}

      <SettingsNav activeKey={section} />
      <Box mt={5} >
        <Container>
          {formAlert && (
            <Box mb={4}>
              <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>
          )}



          {section === "general" && 

          <Grid container={true} spacing={3} style={{zIndex: '1000'}}>
            <Grid item={true} xs={12} sm={6} style={{zIndex: '1000'}}>
            <Card style={{background: "#fff", zIndex: '1000'}}>
            <CardHeader title={'General'} />
              <CardContent style={{background: "#fff", zIndex: '1000'}}>
                   
              <SettingsGeneral onStatus={handleStatus}  style={{background: "#fff"}} />

              </CardContent>
              
            </Card>
            </Grid>


            <Grid item={true} xs={12} sm={6} style={{zIndex: '1000'}}>
            <Card style={{background: "#fff", zIndex: '1000'}}>
            <CardHeader title={'Password'} />
              <CardContent style={{background: "#fff", zIndex: '1000'}}>
                   
              <SettingsPassword onStatus={handleStatus} />     
                    
                    
              </CardContent>
             
            </Card>
            </Grid>

            {team && team.type === 'enterprise' && (
              <Grid item={true} xs={12} sm={6} style={{zIndex: '1000'}}>
              <Card style={{background: "#fff", zIndex: '1000'}}>
              <CardHeader title={'Team'} />
                <CardContent style={{background: "#fff", zIndex: '1000'}}>
                    
                <SettingsTeam onStatus={handleStatus} />
                      
                      
                </CardContent>
              
              </Card>
              </Grid>
            )}

          </Grid>
          }


          {section === "interventions" && <SettingsInterventions teamid={auth.user.team_id} onStatus={handleStatus} />}

          {section === "billing" && <SettingsBilling onStatus={handleStatus} />}

        </Container>
      </Box>
    </Section>
  );
}

export default SettingsSection;
