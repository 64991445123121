import React, { useState, useEffect, useRef, useCallback } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { ShareRequestResultsSummary } from "./ShareRequestResultsSummary";
import { useShareVideoResultOnce, useShareVideoResultScores } from "../util/db";
import { redirectToShareResultCheckout, getStripeSessionDetails } from "./../util/stripe";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';
import { ShareRequestDemoLive } from "./ShareRequestDemoLive";


const moment = require('moment');

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;

const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

export const ShareRequestVideoResults = (props) => {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const detailRef = useRef();

    const { team, shareRequest, legend, isPaid, downloadCallback, scoreCallback, ...otherProps } = props;


    const { data: shareVideoResult, status: svrStatus, error: svrError, } = useShareVideoResultOnce(shareRequest.id);
    const { data: scores, status: scoresStatus, error: scoresError, } = useShareVideoResultScores(shareRequest.id);


    const [data, setData] = useState([]);
    const [immersionDataPoints, setImmersionDataPoints] = useState([]);
    const [safetyDataPoints, setSafetyDataPoints] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    
    
    const [nobuyopen, setNobuyopen] = useState(false);
    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [duration, setDuration] = useState();
    const [accountIds, setAccountIds] = useState(undefined);


    const [immersionOverall, setImmersionOverall] = useState(0);
    const [safetyOverall, setSafetyOverall] = useState(0);
    const [shape, setShape] = useState('flat');

    const [stripeSession, setStripeSession] = useState();
    
    const [completeCount, setCompleteCount] = useState(0);
    const [hasData, setHasData] = useState(false);
   
    //TODO: replace the group id with an array of users
    const groupId = "tues";
    const aggWindow = "1m";
   
  // console.log(shareRequest);

   //console.log("shareVideoResult = " + JSON.stringify(shareVideoResult));
   //console.log(scores);
   

   

   

    useEffect(() => {
        if (shareRequest) {
            //console.log(shareRequest);
            let sr = getMomentFromTimestamp(shareRequest.start).toISOString();
            setStartRange(sr);
            let er = getMomentFromTimestamp(shareRequest.end).toISOString();
            setEndRange(er);
            let du = moment.duration(getMomentFromTimestamp(shareRequest.start).diff(getMomentFromTimestamp(shareRequest.end)));
            setDuration(du);

            

            console.log("start to end duration == " + duration);

            //loadMinuteData(sr, er);
        }

        


      }, []);

      useEffect(() => {
        if (shareVideoResult) {
            //console.log("shareVideoResult");
            //console.log(shareVideoResult);


              setImmersionOverall(shareVideoResult.immersion);
              setSafetyOverall(shareVideoResult.safety);

            

        }
      }, [shareVideoResult]);


      useEffect(() => {
        if (scores) {
            //console.log("scores");
            //console.log(scores);


              //setImmersionOverall(shareVideoResult.immersion);
             // setSafetyOverall(shareVideoResult.safety);


             //const accountIds = shareAccepts.map(item => { return item.account_id; });
             setAccountIds(scores.map(item => { return item.account_id; }));


              aggregateScores();
        }
      }, [scores]);

      
      
const buy = (quantity) => {

    if (quantity >= 1) {
    redirectToShareResultCheckout(shareRequest.id, shareRequest.owner, "pr_tuesday_sr_10", quantity).catch((error) => {
        console.log(error.message);
      });
    } else {
        setNobuyopen(true);
    }

}

const handleClose = () => {
    setNobuyopen(false);
  };

    const averageArray = (nums) => {
        return nums.reduce((a, b) => (a + b)) / nums.length;
    }


    const getMomentFromTimestamp = (timestampObject) => {
        const timestamp = new Timestamp(timestampObject.seconds, timestampObject.nanoseconds);
    
        return moment(new Date(timestamp.toDate()))
    }

    const handleDownloadImage = async (element, name) => {
      //const element: any = overviewRef.current;
      const canvas = await html2canvas(element);
    
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');
    
      if (typeof link.download === 'string') {
        link.href = data;
        link.download = name + '_image.jpg';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    };

    const downloadDetailSection = useCallback(() => {
      handleDownloadImage(detailRef.current, 'SIX_detail');
    }, [scores]);


    const changeView = (newAgg) => {
      aggWindow = newAgg;
      //loadMinuteData(experienceAccountIds, startRange, endRange);
    }


    const aggregateScores = () => {
        var overallImmersion = []
        var overallSafety = []
        var downloadDataset = []
        let finalData = []

        var participants = scores.length;
        var totalSeconds = shareVideoResult.duration_seconds;

        if (participants >= 1) {
          setHasData(true);
            

            for (let index = 0; index < totalSeconds; ++index) {
                var rtI = 0;
                var rtS = 0;
                scores.forEach(scoreItem => {

                  if (Object.hasOwn(scoreItem, 'immersion_points')) {

                    if (scoreItem.immersion_points.length >= index ) {
                        var sI = scoreItem.immersion_points[index]
                        rtI = rtI + sI
                    }

                    if (scoreItem.safety_points.length >= index ) {
                        var sS = scoreItem.safety_points[index]
                        rtS = rtS + sS
                    }
                  } else if (Object.hasOwn(scoreItem, 'immersionPoints')) {
                    if (scoreItem.immersionPoints.length >= index ) {
                      var sI = scoreItem.immersionPoints[index]
                      rtI = rtI + sI
                    }

                    if (scoreItem.safetyPoints.length >= index ) {
                      var sS = scoreItem.safetyPoints[index]
                      rtS = rtS + sS
                    }
                  }

                });
                //{ "x": res[i]['_time'], "y" : Math.round(res[i]['e']) }
                overallImmersion.push({ "x": index, "y" : Math.round(rtI/participants) });
                overallSafety.push({ "x": index, "y" : Math.round(rtS/participants) });

                downloadDataset.push({ "timestamp": index, "value" : Math.round(rtI/participants), "safety" : Math.round(rtS/participants) });


                
            }

            //console.log(`OVERALL IMM: ${overallImmersion}`);
            //console.log(`OVERALL SAFE: ${overallSafety}`);
            
            setImmersionDataPoints(overallImmersion);
            setSafetyDataPoints(overallSafety);

            setDownloadData(downloadDataset);
            downloadCallback(downloadDataset);

            const expShape = calculateShape(downloadDataset);
            scoreCallback(immersionOverall, safetyOverall, expShape);

            finalData.push({"id": "Value Score", "data" : overallImmersion});
            finalData.push({"id": "Safety", "data" : overallSafety});
    
            //console.log(finalData);
    
            setData(finalData);

        } else {
          setHasData(false);
        }



        //setImmersionDataPoints(engagementPoints);
        //setSafetyDataPoints(safetyPoints);
    }


    const calculateShape = (experiencedata) => {
      var myshape = "flat";
//set shape
if (experiencedata && experiencedata.length > 0) {
const data = experiencedata.map(score => {
return {immersion: score.immersion}
})
const totalCount = data.length;
const halfCount = Math.floor(totalCount/2);
//console.log(totalCount);
//console.log(halfCount);

let t = 0;
let tScores = 0;
for(let i = 0; i < data.length; i++) {
  t += i;
  tScores += data[i].immersion;
}

const averageAllTimes = (data.length/2)/2;
const averageAllScores = tScores / data.length;
console.log(`Averages time: ${t} ${averageAllTimes}  scores: ${averageAllScores}`);

let partOneASum = 0;
let partOneBSum = 0;
let partTwoASum = 0;
let partTwoBSum = 0;

for(let i = 0; i < data.length; i++) {
data[i]['a'] = (i+1 - averageAllTimes) * (data[i].immersion - averageAllScores);
data[i]['b'] = Math.pow((i+1 - averageAllTimes),2);

if (i<=halfCount) {
  partOneASum += data[i]['a'];
  partOneBSum += data[i]['b'];
} else {
  partTwoASum += data[i]['a'];
  partTwoBSum += data[i]['b'];
}
}

//console.log(data);

const sectionOne = (partOneASum/partOneBSum);
const sectionTwo = (partTwoASum/partTwoBSum);
const SLOPE_CONSTANT = [-0.02, 0.02];  

console.log(`RESULT: ${sectionOne}   ${sectionTwo}`);

if (  ((SLOPE_CONSTANT[0] <= sectionOne) && (sectionOne < SLOPE_CONSTANT[1])) &&
    ((SLOPE_CONSTANT[0] <= sectionTwo) && (sectionTwo < SLOPE_CONSTANT[1]))
  ) {
    //i am flat
    setShape('flat');
    console.log("i am flat");
    myshape = 'flat';

} else if ( (SLOPE_CONSTANT[0] <= sectionOne) && (sectionTwo >= sectionOne) && (sectionTwo > SLOPE_CONSTANT[1]) ) {
    //i am up/rising
    setShape('up');
    console.log("i am up");
    myshape = 'up';

} else if ( (sectionOne < SLOPE_CONSTANT[1]) && (sectionTwo <= sectionOne) && (sectionTwo < SLOPE_CONSTANT[0]) ) {
    //i am down/dropping
    setShape('down');
    console.log("i am down");
    myshape = 'down';

} else if ( (sectionOne > SLOPE_CONSTANT[1]) && (sectionTwo < SLOPE_CONSTANT[1]) ) {
//i am mountain
setShape('mtn');
console.log("i am mtn");
myshape = 'mtn';

} else if ( (sectionOne < SLOPE_CONSTANT[0]) && (sectionTwo > SLOPE_CONSTANT[0]) ) {
//i am valley
setShape('valley');
console.log("i am valley");
myshape = 'valley';

} else {
//i am unknown...
setShape('flat');
console.log("i am unmatched/flat");
myshape = 'flat';

}




} else {
setShape('solid');
myshape = 'solid';
}

return myshape;


  }








    return (
        <>


            {!!immersionOverall && immersionOverall > 0 && hasData && 
            <>
            
            
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>
              
                    <Grid item xs={6}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        SUMMARY
                    </Typography>
                    </Grid>

                    <Grid item xs={6}>

                    </Grid>

                    <Grid item xs={12}>
                    

                {immersionOverall && safetyOverall && shareRequest && 
                    <ShareRequestResultsSummary immersion={immersionOverall} safety={safetyOverall} title={shareRequest.title} type={shareRequest.type} attendeeCount={shareVideoResult.completed_count}/>
                }
                    </Grid>
                    
                </Grid>
               

                


                { isPaid === false && (
                  <>
                 
                  <hr className={classes.hrMargin}></hr>
              <Grid container spacing={5} alignItems="stretch">
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} justifyContent="center">
              <Typography variant={`h3`} color="textPrimary" gutterBottom={false} style={{textAlign: 'center'}} paragraph={true}>
                  Want the full report?
                  </Typography>

                  <Typography variant={`p`} color="textPrimary" gutterBottom={false} paragraph={true} style={{textAlign: 'center'}} >
                  With the full report, you can see a timeline graph by minute of the complete experience, download the data for deeper analysis and break the timeline into 5, 15, 30, or 60 minute segments to get a deep understanding of how your audience responded to this experience.
                  </Typography>

                  <Typography variant={`p`} color="textPrimary" gutterBottom={false}  paragraph={true} style={{textAlign: 'center'}} >
                  Full report is only $10 per completed recipient!
                  </Typography>

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={(event) => buy(shareVideoResult.completed_count)}  >
                      Buy Now
                  </Button>
                  </div>

                </Grid> 

                <Grid item xs={1} ></Grid>
              </Grid>
              <hr className={classes.hrMargin}></hr>
              </>
              )}


                <Grid container spacing={5} alignItems="stretch" ref={detailRef} style={{marginTop: '50px'}}>

                <Grid item xs={6}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        DETAIL
                    </Typography>
                </Grid>

                <Grid item xs={6} alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                <Grid container  alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
        <Grid item={true} xs={12}  className={classes.actionBar} style={{display: 'grid', justifyContent: "right", justifyItems: "right"}}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  
                
                  
                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon onClick={downloadDetailSection}/> 
                  </Button>
                  
                  <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`SIX_event_export_${aggWindow}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>
                 


                  </ButtonGroup>
                </Grid>
                </Grid>
                </Grid>




              <Grid item xs={12} style={{height: '50vh'}} className={isPaid === true ? classes.noBlur : classes.doBlur}>

                    

                <ResponsiveLine 
  data={data}
  margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
  colors={{ scheme: 'accent' }}
  curve="natural"
  enableGridX={false}
  enableArea={true}
  axisBottom={{
    "orient": "bottom",
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 90,
    "format": (d) => {return d % 10 === 0 ? d : '';},
    "legend": legend,
    "legendOffset": 10,
    "legendPosition": 'middle'
  }}
  
  yScale={{
    type: 'linear',
    min: 0,
    max: 100,
    stacked: false,
    reverse: false
}}
  lineWidth={5}
  pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
  legends={[
    {
        anchor: 'top-left',

        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -30,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
    ]}
/>


                </Grid>
                
                </Grid>


                {
                /*
                <ShareRequestResultsTimeline shareRequest={shareRequest} team={team} startRange={startRange} endRange={endRange} isPaid={isPaid}/>
                */}

              {  accountIds && shareRequest && 
                  <ShareRequestDemoLive shareRequest={shareRequest} startRange={startRange} endRange={endRange} accountIds={accountIds} isPaid={isPaid} />
                }



      { hasData == false && 
              <>
              
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>

                    <Grid item xs={12}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        NO DATA
                    </Typography>

                    <Typography variant={`p`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                        Bummer. We can't find anyone that completed this experience. Adjust your Invite title or reward and try sending again.
                    </Typography>

                
                    </Grid>
                    
                </Grid>


              </>
            }



                </>

            }


    <Dialog
        open={nobuyopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"No completed participants"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There were no completed participants for this experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>



        </>
    )


};

