import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "./../util/router";

function SettingsNav(props) {
  return (
    <Tabs
      value={props.activeKey}
      indicatorColor="secondary"
      textColor="textPrimary"
      centered={true}
    >
      <Tab
        component={Link}
        to="/settings/general"
        label="General"
        value="general"
      />
      <Tab
        component={Link}
        to="/settings/interventions"
        label="Interventions"
        value="interventions"
      />
      <Tab
        component={Link}
        to="/settings/billing"
        label="Billing"
        value="billing"
      />
    </Tabs>
  );
}

export default SettingsNav;
