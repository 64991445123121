import React, { useState, useEffect, useRef, useCallback } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ResultIcon from "./immersion/ResultIcon";
import Grid from "@material-ui/core/Grid";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';

const moment = require('moment');

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;

const useStyles = makeStyles((theme) => ({
    headline: {
      fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    paper: {
        padding: '6px 16px',
        borderRadius: '10px',
        background:'linear-gradient(#ffffff, #C1C2FF)',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
      timebox: {
        backgroundColor: "#eeeeee",
        padding: '20px',
      },
      divider: {
        marginTop: "40px",
        marginBottom: '20px',
      },
      button: {
        background: '#000000',
      }
  }));



  export const ShareRequestResultsTimeline = (props) => {
    const classes = useStyles();
    const timelineRef = useRef();
    const [data, setData] = useState([]);
    const [aggWindow, setAggWindow] = useState("15m");
    const { team, shareRequest, startRange, endRange, accountIds, legend, isPaid, ...otherProps } = props;

    useEffect(() => {
       // console.log("ShareRequestResultsTimeline useEffect");

        if (shareRequest) {
           // console.log(shareRequest);
            
            loadData(accountIds, startRange, endRange, aggWindow);
        }
      }, [shareRequest, startRange, endRange, aggWindow, accountIds]);


      
      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

      const downloadTimelineSection = useCallback(() => {
        handleDownloadImage(timelineRef.current, 'SIX_timeline');
      }, [shareRequest]);



      const arrayToQueryString = (arr) => {
        var r = "[\"";
        let authorString = arr.join("\",\"");
        //console.log(authorString);
        const x = r + authorString + "\"]";
        //console.log(x);
        return x;
      }


    const loadData = (accountIds, start, end, window) => {
        //console.log(`ShareRequestResultsTimeline loadData == ${accountIds}`);
        //console.log(`ShareRequestResultsTimeline loadData == ${arrayToQueryString(accountIds)}`);

        let localQuery = `data = from(bucket: "${bucket}")
            |> range(start: ${start}, stop: ${end})
            |> filter(fn: (r) => r["_measurement"] == "score")
            |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
            |> filter(fn: (r) => contains(value: r["pid"], set: ${arrayToQueryString(accountIds)}))
            |> aggregateWindow(every: ${window}, fn: mean, createEmpty: false)
            |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
            |> group(columns: ["_time"], mode: "by")
            
            mean_e = data
            |> mean(column: "e")

            mean_s = data
              |> mean(column: "s")

            join(tables: {t1: mean_e, t2: mean_s}, on: ["_time"])`;

            //console.log("localQuery = " + localQuery);

        let res = [];
        const influxQuery = async () => {
          //create InfluxDB client
          const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
          //console.log(queryApi);
    
          //make query
          await queryApi.queryRows(localQuery, {
            next(row, tableMeta) {
             //  console.log("a");
              const o = tableMeta.toObject(row);
             //push rows from query into an array object
              res.push(o);
            },
            complete() {
              
              let finalData = []
              let engagement = []
              let safety = []

    
              for(let i = 0; i < res.length; i++) {

                const e = res[i]['e'] !== undefined ? res[i]['e'] : 0.0;
                const s = res[i]['s'] !== undefined ? res[i]['s'] : 0.0;


                engagement.push({ "x": res[i]['_time'], "y" : e });
                safety.push({ "x" : res[i]['_time'], "y" : s });
    
                var endDate = moment(res[i]['_time']).toDate();
                var startDate = endDate
                if (window === "5m") {
                    startDate = moment(res[i]['_time']).subtract(5, "minutes").toDate();

                    //startDate = new Date((endDate.getTime()/1000) - (60 * 5));
                } else if (window === "15m") {
                    startDate = moment(res[i]['_time']).subtract(15, "minutes").toDate();

                    //startDate = new Date((endDate.getTime()/1000) - (60 * 15));
                } else if (window === "30m") {
                    startDate = moment(res[i]['_time']).subtract(30, "minutes").toDate();
                    //startDate = new Date((endDate.getTime()/1000) - (60 * 30));
                } else if (window === "1h") {
                    startDate = moment(res[i]['_time']).subtract(60, "minutes").toDate();
                    //startDate = new Date((endDate.getTime()/1000) - (60 * 60));
                }

                finalData.push({"id": i, "start": startDate, "end": endDate, "immersion": Math.round(e), "safety": Math.round(s)});
    
                
              }
    
    
              setData(finalData);
              
            },
            error(error) {
              console.log("query failed- ", error);
            }
          });
         
        };
    
        influxQuery();
    
      }

      const changeView = (view) => {
        setAggWindow(view);

        loadData(startRange, endRange, view);

      }


    return (
        <>









<Grid container spacing={5} alignItems="stretch" style={{marginTop: '60px'}} ref={timelineRef}>
              
              <Grid item xs={12} md={6} >
                <Typography variant={`h4`} color="textPrimary" gutterBottom={false} >
                        TIMELINE
                </Typography>

              </Grid>
              <Grid item xs={12} md={6} style={{display: 'grid', justifyContent: "right", justifyItems: "right"}} >
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('5m')} className={classes.button} disabled={aggWindow === '5m' || isPaid === false}>
                      5m
                  </Button>
                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('15m')} className={classes.button} disabled={aggWindow === '15m' || isPaid === false}>
                      15m
                  </Button>
                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('30m')} className={classes.button} disabled={aggWindow === '30m' || isPaid === false}>
                      30m
                  </Button>
                  <Button variant="contained" color="secondary" size="medium" onClick={(event) => changeView('1h')} className={classes.button} disabled={aggWindow === '1h' || isPaid === false}>
                      1h
                  </Button>

                  
                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon onClick={downloadTimelineSection}/> 
                  </Button>

                  <CustomCvsButton variant="contained" color="secondary" data={data} filename={`SIX_timeline_export_${aggWindow}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>
                 

                </ButtonGroup>
              </Grid>


                   


              
              <Grid item xs={12} className={isPaid === true ? classes.noBlur : classes.doBlur}>


    {data && data.length >= 1 && 

        <Timeline align="alternate">

{data.map((item, index) => 
      <TimelineItem key={item.id}>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            { moment(item.start).format("h:mm a") }
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{backgroundColor: "#ffffff00", boxShadow: "none"}}>
          <ResultIcon maxheight={'70px'} maxwidth={'70px'} fontsize={'35px'} scoretopbuffer={'10px'} experiencedata={[]} immersionScore={item.immersion} safetyScore={item.safety} />

          </TimelineDot>
          <TimelineConnector style={{backgroundColor: "#d7d7d7", minHeight: '30px'}} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Value: {item.immersion}
            </Typography>
            <Typography>Safety: {item.safety}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

  )}
              


      </Timeline>

            }

      </Grid>
      
      </Grid>

   

        </>
    )
  };