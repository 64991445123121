import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
  holdroot: {
    position: "relative",
    width: "80vw",
    maxWidth: "1200px",
    marginLeft: "auto",
  marginRight: "auto",
    // Ensure child <Container> is above background
    // image (if one is set with the bgImage prop).
    "& > .MuiContainer-root": {
      position: "relative",
    },
  },


  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  imageContainer: {
    margin: "0 auto",
    //maxWidth: "800px",
    width: '400px',
  height: '400px',
    marginBottom: "30px",
    "& img": {
      width: '100%',
  height: '100%',
      
    },
    imageRounded: {
      borderRadius: "25px",
    },
  },

  masterButton: {
    
    borderRadius: '10px',
    background:'linear-gradient(#AEB2FF, #AEB2FF) padding-box',
	  backgroundColor: '#AEB2FF',
	  border: '2px solid transparent',
   
  },

  narrowText: {
    maxWidth: 400,
    display: "inline-block",
  },

}));

function HeroSectionHome(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}

    >
      <Container>

      <Grid container={true}>
            
            <Grid
                  item={true}
                  xs={12}
                  md={6}
                  className={classes.gridItem}
                  
                >
                  
                  <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
          />
          <Button
            component={Link}
            to={props.buttonPath}
            //variant="contained"
            size="large"
            className={classes.masterButton}
          >
            {props.buttonText}
          </Button>
        </Box>

                </Grid>

                <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                
              >

                  


              </Grid>

      </Grid>



       
      </Container>
    </Section>
  );
}

export default HeroSectionHome;
