import React from "react";
import Meta from "./../components/Meta";
import HeroSectionHome from "./../components/HeroSectionHome";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import TestimonialsSectionImages from "./../components/TestimonialsSectionImages";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";
import Section from "./../components/Section";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  root: {
    position: "relative",
    minWidth: "100vw",
    maxWidth: "1200px",
    marginLeft: "auto",
  marginRight: "auto",
    // Ensure child <Container> is above background
    // image (if one is set with the bgImage prop).
    "& > .MuiContainer-root": {
      position: "relative",
    },
  },

}));

function IndexPage(props) {
  const classes = useStyles();

  return (
    <>




      <Meta />
      <HeroSectionHome
        bgColor="primary"
        size="large"
        bgImage="/images/six_header_round_workers_v2.jpg"
        bgImageOpacity={1}
        title="RADICAL FULFILLMENT FOR TEAMS."
        subtitle=""
        buttonText="Get Started"
        buttonColor="#ff0000"
        buttonPath="/pricing"
        //mainImage="/images/six_header_sq_workers.jpg"
      />

{/** 
<Section
      bgColor="default"
      size="large"
      bgImage="/images/large_grad.jpg"

    >
*/}

<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="CONTINUOUS EMPLOYEE DEVELOPMENT"
        bodyText="SIX facilitates employee development by using brain activity to identify each employee's most productive activities and to indicate when additional training is needed. This scalable employee benefit directly increases job satisfaction and reduces turnover."
        subtitle="All the features you need to move faster"
        mainImage="/images/two_phones_six-2.png"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />

<ContentSectionImageRight
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="OPTIMIZE TEAM PERFORMANCE"
        bodyText="SIX's real-time dashboard gives leaders objective data on organizational culture for both in-office and remote employees. Aggregated anonymous brain activity shows which teams and departments are achieving high performance and which need attention. SIX also objectively quantifies the ROI from culture inventions by measuring the productivity of employees."
        subtitle="All the features you need to move faster"
        mainImage="/images/six_two_phones_cal_live.png"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />

<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="SIMPLE TO DEPLOY"
        bodyText="Employees receive a link to the SIX app that syncs with their smartwatches. They can choose to share anonymized data with leadership without survey fatigue or bias reporting. High performers are rare and SIX ensures work environments allow employees to flourish and do their best work."
        subtitle="All the features you need to move faster"
        mainImage="/images/six_phone_watch_setup.png"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />



<ContentSectionImageLeft
        bgColor=""
        size="large"
        bgImage="/images/six-enterprise-priv-section.jpg"
        bgImageOpacity={1}
        title="PRIVACY FIRST"
        bodyText="SIX data are anonymous and shown in aggregate so employees can be confident that SIX will improve their work lives. Employees using SIX report increased support from leadership and colleagues as well as more job satisfaction."
        subtitle="All the features you need to move faster"
        mainImage=""
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
{/**</Section>**/}


      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <ClientsSection
        bgColor="purple5"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="We've got your 6"
        subtitle="Join the many other companies that are using the science behind SIX to inspire people."
      />
      
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage="/images/white_green_grad.jpg"
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage="/images/six_pattern_bkgrd.jpg"
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

      <Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams. Powered by Value Measurement and the science of Immersion Neuroscience Inc."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />


    </>
  );
}

export default IndexPage;
