import { useRef, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from "./firebase";

//import { getMessaging } from 'firebase-admin/messaging'


//import { firebaseAdmin } from '../../api/_firebase';
//import admin from 'firebase-admin';

const auth = getAuth(firebaseApp);
const branch = require("branch-sdk");

//console.log(process.env.REACT_APP_BRANCH_KEY);


branch.init(process.env.REACT_APP_BRANCH_KEY, function(err, data) {
	console.log(err, data);
});



// Make an API request to `/api/{path}`
export async function apiRequest(path, method = "GET", data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth);
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

export function createShortId(length) {
  let chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}


export function teamDomain(emailAddr) {
  var parts = emailAddr.split("@");
  return parts[1];
}

export function teamLogo(domain) {
  return "https://logo.immersion.cx/" + domain.trim();
}


export async function branchLink(linkData) {
  return await new Promise((resolve, reject) => {

      branch.link(linkData, function(error, link){
          if (error) {
              reject(error)
          } else {
              resolve(link);
          }
      });

  })}



export async function createBranchTeamJoinLink(teamid, teamdomain, email) {
  var linkData = {
    campaign: 'general',
    channel: 'enterprise',
    feature: 'team_invite',
    stage: 'admin',
    tags: [ 'team_invite' ],
    alias: '',
    data: {
      'team_id': teamid,
      'team_domain': teamdomain,
      'user': email
    }
  };
  
  return branch.link(linkData, function(err, link) {
    console.log(link);
    return link;
  });


}


export async function createTeamJoinLink(teamid) {
  return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({"longDynamicLink": `https://tuesday.page.link/?link=https://besttuesdayever.com/group/${teamid}&ibi=com.immersion.neuro.tuesday.tuesday&isi=6449885349`, "suffix": {
      "option": "SHORT"
    }}),
  })
  .then(response => response.json())
  .then((res) => {
      return res.shortLink
    })
    .catch((err) => console.log('error'))
}

export async function createShareRequestInviteLink(shareResquestId) {
  return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({"longDynamicLink": `https://tuesday.page.link/?link=https://besttuesdayever.com/share_request/${shareResquestId}&ibi=com.immersion.neuro.tuesday.tuesday&isi=6449885349`, "suffix": {
      "option": "SHORT"
    }}),
  })
  .then(response => response.json())
  .then((res) => {
      return res.shortLink
    })
    .catch((err) => console.log('error'))
}


export async function sendNotificationToClient(tokens, data) {
  // Send a message to the devices corresponding to the provided
  // registration tokens.

console.log(tokens);
console.log(data);

/*
messaging
    .sendMulticast({ tokens, data })
    .then(response => {
      // Response is an object of the form { responses: [] }
      const successes = response.responses.filter(r => r.success === true)
        .length;
      const failures = response.responses.filter(r => r.success === false)
        .length;
      console.log(
        'Notifications sent:',
        `${successes} successful, ${failures} failed`
      );
    })
    .catch(error => {
      console.log('Error sending message:', error);
    });
    */
};
