import React from "react";
import Meta from "./../components/Meta";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router";
import { requireAuth } from "./../util/auth";
import Footer from "./../components/Footer";

function SettingsPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Settings" />
      <SettingsSection
        bgColor="default"
        size="medium"
        bgImage="/images/six-enterprise-master-bkgrd.jpg"
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />

<Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />


    </>
  );
}

export default requireAuth(SettingsPage);
