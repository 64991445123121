import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import Footer from "./../components/Footer";
import HeroSection2 from "./../components/HeroSection2";
import HubspotForm from "react-hubspot-form";
import HomeSectionHero from "./../components/HeroSectionHome";


function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <HomeSectionHero
        bgColor="primary"
        size="large"
        bgImage="/images/six-enterprise-header-contact.jpg"
        bgImageOpacity={1.0}
        buttonText="Get Answers"
        buttonColor="#ff0000"
        buttonPath="#faq"
        title="Have Questions?"
        subtitle="Drop us a note and let's schedule a time to talk.  We would love to learn more about your goals and would be happy to share a demo."
      />



      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />

<Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />

    </>
  );
}

export default ContactPage;
