import React from "react";
import Meta from "../components/Meta";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import HeroSection2 from "../components/HeroSection2";
import { InlineWidget } from "react-calendly";


function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <HeroSection2
        bgColor="secondary"
        size="large"
        bgImage="./images/schedule.jpg"
        bgImageOpacity={0.4}
        title="Ready to Schedule a Demo?"
        subtitle="Drop us a note and let's schedule a time to talk.  We would love to learn more about your goals and would be happy to share a demo."
      />



      <InlineWidget url="https://calendly.com/d/ckcc-4rw-n3k/six-for-teams-demo" />



      <Footer
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />

    </>
  );
}

export default ContactPage;
